import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Hva er overtråkk?</h1>
      <p>
        Overtråkk er når vi tråkker over med foten og får en strekk i leddbånd, muskelvev og/eller nervevev. Smertene
        sitter lokalt på innsiden eller utsiden av ankelen. Oftest er det utsiden man tråkker over på. En større eller
        mindre grad av hevelse vil alltid følge overtråkk. Et stort ankeltrauma kan lede til hel eller delvis avrivning
        av muskelfester eller leddbånd. Den strekken man får i nervevevet leder til «funksjonell ankelinstabilitet» og
        strekken man får i leddbånd og muskel/sene leder til «mekanisk ankelinstabilitet». Kombinasjonen gir ofte en
        kronisk ankelinstabilitet der man lett tråkker over igjen og igjen. Faren med ubehandlet ankelinstabilitet er at
        skaden som oppstår på nervevevet kan inaktivere viktige muskler helt oppe i hofte og korsrygg. Den mekaniske
        instabiliteten kan gi feilbelastning spesielt på kne, hofte og korsrygg. Uten behandling vil man med stor
        sannsynlighet være mer utsatt for problemer i disse områdene senere i livet.
      </p>

      <p>
        Kiropraktoren ved Kiropraktor Akutten Stavanger/Sandnes har skrevet en litteraturstudie på kronisk
        ankelinstabilitet og har veldig gode forutsetninger for å kunne behandle dette. Det er viktig å behandle både
        den funksjonelle og mekaniske ankelinstabiliteten. Hver behandling varer fra 20-30 minutt.
      </p>
    </Layout>
  )
}

export default Page
